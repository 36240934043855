import React, { Dispatch, Reducer, ReducerAction, ReducerState } from 'react';

type State = {
  isMobile: boolean;
  isSmall: boolean;
  isMedium: boolean;
  isLarge: boolean;
  isXLarge: boolean;
  width: number;
};

type Action = { type: 'update'; width: number };

export const defaultState: State = {
  isMobile: true,
  isSmall: true,
  isMedium: true,
  isLarge: true,
  isXLarge: true,
  width: 1440,
};

export function layoutReducer(state: State, action: Action): State {
  switch (action.type) {
    case 'update':
      return {
        isMobile: action.width > 0,
        isSmall: action.width >= 768,
        isMedium: action.width >= 1024,
        isLarge: action.width >= 1170,
        isXLarge: action.width >= 1320,
        width: action.width,
      };
  }
}

const LayoutContext = React.createContext<{
  state: ReducerState<Reducer<State, Action>>;
  dispatch: Dispatch<ReducerAction<Reducer<State, Action>>>;
}>({
  state: defaultState,
  dispatch: () => {
    return;
  },
});

export default LayoutContext;
