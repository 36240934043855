import Footer from 'components/footer';
import Header from 'components/header';
import React from 'react';
import 'styles/global.scss';
import 'styles/typography.scss';
import LayoutContext, { layoutReducer, defaultState as layoutDefault } from './reducer';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import { useTranslation } from 'gatsby-plugin-react-i18next';

type Props = {
  children: React.ReactNode;
};

const isBrowser = typeof window !== 'undefined';

const MainLayout: React.FC<Props> = ({ children }) => {
  const { i18n } = useTranslation();
  const [state, dispatch] = React.useReducer(layoutReducer, layoutDefault);

  const contextValue = React.useMemo(
    () => ({
      state,
      dispatch,
    }),
    [state, dispatch]
  );

  // Resolve viewport height
  const setAppHeight = React.useCallback(() => {
    if (!isBrowser) return;

    const doc = document.documentElement;
    doc.style.setProperty('--vh', `${parseFloat((window.innerHeight * 0.01).toFixed(2))}px`);
  }, []);

  const setAppWidth = React.useCallback(() => {
    if (!isBrowser) return;

    dispatch({
      type: 'update',
      width: window.innerWidth,
    });
  }, []);

  React.useEffect(() => {
    if (!isBrowser) return;
    setAppWidth();
    setAppHeight();

    if (state.isSmall) {
      window.addEventListener('resize', setAppHeight);
    } else {
      window.removeEventListener('resize', setAppHeight);
    }

    window.addEventListener('resize', setAppWidth);

    return () => {
      window.removeEventListener('resize', setAppHeight);
      window.removeEventListener('resize', setAppWidth);
    };
  }, [setAppHeight, setAppWidth, state.isSmall]);

  return (
    <LayoutContext.Provider value={contextValue}>
      <HelmetDatoCms htmlAttributes={{ lang: i18n.language }}>
        <meta name="theme-color" content="#25F906" />
      </HelmetDatoCms>
      <Header />
      {children}
      <Footer />
    </LayoutContext.Provider>
  );
};

export default MainLayout;
