import React, { CSSProperties } from 'react';
import clsx from 'clsx';

import * as styles from './column.module.scss';
import { ComponentPropsWithoutRef } from 'react';

type ColumnWidth = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
type ColumnOffset = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11;
type ColumnObject = { width: ColumnWidth; offset: ColumnOffset };
export type ColumnConfig = ColumnObject | ColumnWidth;

export type ColumnResponsiveSizes = {
  /** Column width */
  xl?: ColumnConfig;
  lg?: ColumnConfig;
  md?: ColumnConfig;
  sm?: ColumnConfig;
  xs?: ColumnConfig;
};

export type ColumnProps<T extends React.ElementType> = ColumnResponsiveSizes & {
  as?: T;
  className?: string;
  children?: React.ReactNode;
  style?: CSSProperties;
  innerGap?: boolean;
};

const getGridStyles = ({ xl = 12, lg = 12, md = 12, sm = 12, xs = 12 }: ColumnResponsiveSizes) =>
  Object.entries({ xl, lg, md, sm, xs })
    .map(([key, val]) => {
      const classNames = [];
      let width = val;
      let offset;
      if (typeof val !== 'number') {
        width = val.width;
        offset = val.offset;
      }
      classNames.push('col' + key.toUpperCase() + width);

      if (offset) {
        classNames.push('col' + key.toUpperCase() + 'Offs' + offset);
      }

      return classNames;
    })
    .map((styleList) => styleList.map((style) => (styles as never)[style]));

const Column = <T extends React.ElementType = 'div'>({
  as,
  children,
  className,
  xl,
  lg,
  md,
  sm,
  xs,
  ...props
}: ColumnProps<T> & Omit<ComponentPropsWithoutRef<T>, keyof ColumnProps<T>>): JSX.Element => {
  const classNames = clsx(
    'column',
    styles.col,
    props.innerGap && styles.innerGap,
    getGridStyles({ xl, lg, md, sm, xs }),
    className
  );
  const Component = as || 'div';

  return (
    <Component className={classNames} {...props}>
      {children}
    </Component>
  );
};

export default Column;
