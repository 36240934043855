import clsx from 'clsx';
import React from 'react';
import { capitalize } from 'utils/functions';

import * as styles from './section.module.scss';

export type SectionColor = 'Blue' | 'Mint' | 'White' | 'Light' | 'Concrete' | 'Black';

export type SectionProps<T extends React.ElementType> = {
  as?: T;
  children?: React.ReactNode;
  sectionClass?: string;
  fullWidth?: boolean;
  className?: string;
  backgroundColor?: SectionColor | string;
  topBackgroundColor?: SectionColor | string;
  topBackgroundOffset?: number | string;
};

const Section = React.forwardRef(
  <T extends React.ElementType = 'section'>(
    {
      as,
      children,
      sectionClass,
      className,
      fullWidth = false,
      backgroundColor = 'Blue',
      topBackgroundColor = 'Blue',
      topBackgroundOffset,
      ...props
    }: SectionProps<T>,
    ref?: React.ComponentPropsWithRef<T>
  ) => {
    const classNames = clsx(
      sectionClass,
      styles.background,
      (styles as never)[`background${capitalize(backgroundColor)}`]
    );
    const topClassNames = clsx(
      topBackgroundColor ? styles.backgroundTop : null,
      (styles as never)[`backgroundTop${capitalize(topBackgroundColor)}`]
    );
    const Component = as || 'section';

    return (
      <Component ref={ref} className={classNames} {...props}>
        {topBackgroundOffset && (
          <span
            className={topClassNames}
            style={{
              height:
                typeof topBackgroundOffset == 'string'
                  ? topBackgroundOffset
                  : `${topBackgroundOffset}%`,
            }}
          />
        )}
        <div className={clsx(className, { [styles.sectionFullWidth]: fullWidth }, styles.section)}>
          {children}
        </div>
      </Component>
    );
  }
);

Section.displayName = 'Section';
export default Section;
