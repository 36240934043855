import Column from 'components/column';
import Link, { LinkType } from 'components/link';
import MatrixFigure from 'components/matrix-figure';
import Section from 'components/section';
import { graphql, useStaticQuery } from 'gatsby';
import Logo from 'images/svg/logo-horizontal.inline.svg';
import React from 'react';
import { useTranslation } from 'react-i18next';

import * as styles from './footer.module.scss';

const Footer: React.FC = () => {
  const { t } = useTranslation();
  const { footer, site } = useStaticQuery<GatsbyTypes.FooterQueryQuery>(graphql`
    query FooterQuery {
      site {
        buildTime(formatString: "YYYY")
      }
      footer: datoCmsFooter {
        leftColumnLinks {
          id
          externalUrl
          text
          link {
            ...LinkCollection
          }
        }
        middleColumn
        rightColumnLinks {
          id
          externalUrl
          text
          link {
            ...LinkCollection
          }
        }
      }
    }
  `);

  const openCookieSettings = React.useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (!window || !(window as any).cookiehub) return;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).cookiehub.openSettings();
  }, []);

  return (
    <Section as={'footer'} fullWidth backgroundColor={'white'} className={styles.footer}>
      <Column xl={9} lg={9} md={10} sm={8} xs={8}>
        <Column className={styles.footerColumn} xl={5} lg={5} md={5}>
          {footer?.leftColumnLinks?.map(
            (link) =>
              link && (
                <h2 key={link.id}>
                  <Link link={link.link as LinkType} externalUrl={link.externalUrl}>
                    {link.text}
                  </Link>
                </h2>
              )
          )}
        </Column>
        <Column className={styles.footerColumn} xl={7} lg={7} md={7}>
          {footer?.middleColumn && (
            <div dangerouslySetInnerHTML={{ __html: footer.middleColumn }} />
          )}
        </Column>
      </Column>
      <Column className={styles.footerColumn} xl={3} lg={3} md={2} sm={4} xs={4}>
        {footer?.rightColumnLinks?.map(
          (link) =>
            link && (
              <h2 key={link.id}>
                <Link link={link.link as LinkType} externalUrl={link.externalUrl}>
                  {link.text}
                </Link>
              </h2>
            )
        )}
      </Column>
      <Column className={styles.footerBottomSection} xl={4} lg={4} md={5} sm={6}>
        <Logo className={styles.footerLogo} />
        <div>
          {`© Inhouse Tech Göteborg ${site?.buildTime} | `}
          <button onClick={openCookieSettings} className={styles.footerCookieBtn}>
            {t('Cookie inställningar')}
          </button>
        </div>
      </Column>
      <MatrixFigure className={styles.matrixFigure} size={'large'} />
    </Section>
  );
};

export default Footer;
