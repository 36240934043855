// extracted by mini-css-extract-plugin
export var section = "section-module--section--YiRvb";
export var sectionFullWidth = "section-module--sectionFullWidth--tIGtj";
export var background = "section-module--background--oEmgw";
export var backgroundTop = "section-module--backgroundTop--8C4Ua";
export var backgroundTopBlue = "section-module--backgroundTopBlue--gH71w";
export var backgroundTopMint = "section-module--backgroundTopMint--ahKdA";
export var backgroundTopWhite = "section-module--backgroundTopWhite--YBSAs";
export var backgroundTopLight = "section-module--backgroundTopLight--OZ2CR";
export var backgroundTopConcrete = "section-module--backgroundTopConcrete--ZMLkc";
export var backgroundTopBlack = "section-module--backgroundTopBlack--1Tsh9";
export var backgroundBlue = "section-module--backgroundBlue--QYTyt";
export var backgroundMint = "section-module--backgroundMint--lLLqc";
export var backgroundWhite = "section-module--backgroundWhite--eIvrx";
export var backgroundLight = "section-module--backgroundLight--9+-cW";
export var backgroundConcrete = "section-module--backgroundConcrete--gt1Jp";
export var backgroundBlack = "section-module--backgroundBlack--MgGbd";