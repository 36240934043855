import clsx from 'clsx';
import Link, { LinkType } from 'components/link';
import { graphql, useStaticQuery } from 'gatsby';
import Logo from 'images/svg/logo-horizontal.inline.svg';
import React from 'react';
import { HelmetDatoCms } from 'gatsby-source-datocms';

import * as styles from './header.module.scss';

const Header: React.FC = () => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  const { datoCmsMainMenu, datoCmsSite } = useStaticQuery<GatsbyTypes.HeaderQueryQuery>(graphql`
    query HeaderQuery {
      datoCmsMainMenu {
        links {
          ...Link
        }
      }
      datoCmsSite {
        faviconMetaTags {
          tags
        }
      }
    }
  `);

  return (
    <header className={styles.header}>
      <HelmetDatoCms seo={datoCmsSite?.faviconMetaTags} />
      <div
        className={clsx(styles.hamburger, { [styles.hamburgerOpen]: isOpen })}
        onClick={() => setIsOpen((o) => !o)}
      >
        <div className={styles.hamburgerIcon}>
          <span />
        </div>
      </div>
      <div className={clsx(styles.headerContainer, { [styles.headerContainerOpen]: isOpen })}>
        <div className={styles.headerLinkContainer}>
          {datoCmsMainMenu?.links?.map(
            (link) =>
              link && (
                <Link
                  key={link.id}
                  onClick={() => setIsOpen(false)}
                  className={styles.headerLinkItem}
                  activeClassName={styles.headerLinkItemActive}
                  link={link.link as LinkType}
                  externalUrl={link.externalUrl}
                >
                  {link.text}
                </Link>
              )
          )}
        </div>
        <Logo />
      </div>
    </header>
  );
};

export default Header;
