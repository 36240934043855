// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-contact-page-index-tsx": () => import("./../../../src/templates/contact-page/index.tsx" /* webpackChunkName: "component---src-templates-contact-page-index-tsx" */),
  "component---src-templates-news-archive-index-tsx": () => import("./../../../src/templates/news-archive/index.tsx" /* webpackChunkName: "component---src-templates-news-archive-index-tsx" */),
  "component---src-templates-news-index-tsx": () => import("./../../../src/templates/news/index.tsx" /* webpackChunkName: "component---src-templates-news-index-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-project-archive-index-tsx": () => import("./../../../src/templates/project-archive/index.tsx" /* webpackChunkName: "component---src-templates-project-archive-index-tsx" */),
  "component---src-templates-project-index-tsx": () => import("./../../../src/templates/project/index.tsx" /* webpackChunkName: "component---src-templates-project-index-tsx" */)
}

