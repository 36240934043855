import clsx from 'clsx';
import React from 'react';
import ArrowRight from 'images/svg/arrow-right.inline.svg';
import ArrowLeft from 'images/svg/arrow-left.inline.svg';
import CaretDown from 'images/svg/caret-down.inline.svg';

import * as styles from './arrow.module.scss';

export type ArrowProps = {
  className?: string;
  dir: 'right' | 'left' | 'bottomRight' | 'largeRight' | 'caretDown';
  fillStroke?: boolean;
  color?: 'Blue' | 'Neon';
};

const Arrow: React.FC<ArrowProps> = ({ color = 'Neon', ...props }) => {
  const classNames = clsx(
    props.className,
    styles.arrow,
    props.fillStroke && styles.arrowFill,
    (styles as never)[`bg${color}`],
    (styles as never)[`arrowFill${props.dir}`]
  );

  if (!props.fillStroke) {
    switch (props.dir) {
      case 'right':
        return <ArrowRight className={classNames} />;
      case 'left':
        return <ArrowLeft className={classNames} />;
      case 'caretDown':
        return <CaretDown className={classNames} />;
      case 'bottomRight':
      case 'largeRight':
        return (
          <span className={classNames}>
            <span />
          </span>
        );
    }
  }

  return <span className={classNames} />;
};

export default Arrow;
