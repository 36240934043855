// extracted by mini-css-extract-plugin
export var large = "header-module--large--vGViA";
export var header = "header-module--header--4dQNe";
export var headerContainer = "header-module--headerContainer--vr5DC";
export var headerContainerOpen = "header-module--headerContainerOpen--Dr+EY";
export var headerLinkContainer = "header-module--headerLinkContainer--eKVO4";
export var headerLinkItem = "header-module--headerLinkItem--8UzYU";
export var headerLinkItemActive = "header-module--headerLinkItemActive--Fe0NM";
export var hamburger = "header-module--hamburger--uEOZj";
export var hamburgerIcon = "header-module--hamburgerIcon--KAKea";
export var hamburgerOpen = "header-module--hamburgerOpen--lHSKX";