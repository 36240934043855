import { EmblaCarouselType } from 'embla-carousel';

export const capitalize = (str?: string): string => {
  if (!str) return '';
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const getDiffToTarget = (slider: EmblaCarouselType, includeAll = false): number[] => {
  const engine = slider.dangerouslyGetEngine();
  const scrollProgress = slider.scrollProgress();

  const diffs = slider.scrollSnapList().map((scrollSnap, index) => {
    const slidesInView = slider.slidesInView();
    slidesInView.push(Math.min(...slider.slidesInView()) - 1);
    slidesInView.push(Math.max(...slider.slidesInView()) + 1);
    if (!slidesInView.includes(index) && !includeAll) return -100;
    let diffToTarget = scrollSnap - scrollProgress;

    if (engine.options.loop) {
      engine.slideLooper.loopPoints.forEach((loopItem) => {
        const target = loopItem.getTarget();
        if (index === loopItem.index && target !== 0) {
          const sign = Math.sign(target);
          if (sign === -1) diffToTarget = scrollSnap - (1 + scrollProgress);
          if (sign === 1) diffToTarget = scrollSnap + (1 - scrollProgress);
        }
      });
    }

    if (Number.isNaN(diffToTarget)) return -100;

    return diffToTarget;
  });

  return diffs;
};

export const preventEdgeScrolling = (embla: EmblaCarouselType): (() => void) => {
  const { limit, target, location, scrollTo } = embla.dangerouslyGetEngine();

  return () => {
    if (limit.reachedMax(target.get())) {
      if (limit.reachedMax(location.get())) location.set(limit.max);
      target.set(limit.max);
      scrollTo.distance(0, false);
    }
    if (limit.reachedMin(target.get())) {
      if (limit.reachedMin(location.get())) location.set(limit.min);
      target.set(limit.min);
      scrollTo.distance(0, false);
    }
  };
};

export const uniqBy = <T>(data: T[], key: (item: T) => string): T[] => [
  ...new Map(data.map((item) => [key(item), item])).values(),
];

export type RequireOnlyOne<T, Keys extends keyof T = keyof T> = Pick<T, Exclude<keyof T, Keys>> &
  {
    [K in Keys]-?: Required<Pick<T, K>> & Partial<Record<Exclude<Keys, K>, undefined>>;
  }[Keys];

export const stripTrailingSlash = (val: string): string => {
  if (/\/$/.test(val)) {
    return stripTrailingSlash(val.replace(/\/$/, ''));
  }
  return val;
};
