import { animated } from '@react-spring/web';
import { Spring } from '@react-spring/core';
import Cube from 'images/svg/cube.inline.svg';
import clsx from 'clsx';
import React from 'react';
import largeFigures from './large-figures.json';
import smallFigures from './small-figures.json';
import VisibilitySensor from 'react-visibility-sensor';
import { RequireOnlyOne } from 'utils/functions';

import * as styles from './matrix-figure.module.scss';

type AnimatedCubeProps = {
  delay: number;
};

const AnimatedCube: React.FC<AnimatedCubeProps> = ({ delay }) => {
  return (
    <Spring
      delay={500 + Math.pow(delay, 1.3) * 30}
      config={{
        duration: 20,
      }}
      from={{
        opacity: 0,
        transform: 'translate3d(0,0,0)',
      }}
      to={{
        opacity: 1,
        transform: 'translate3d(0,0,0)',
      }}
    >
      {(style) => (
        <animated.div className={styles.cube} style={style}>
          <Cube />
        </animated.div>
      )}
    </Spring>
  );
};

interface IHash {
  [details: string]: number;
}

type MatrixFigureProps = {
  size: 'large' | 'small';
  className?: string;
  isVisible?: boolean;
  fig?: (' ' | 'x' | 'a')[][];
};

const MatrixFigure: React.FC<RequireOnlyOne<MatrixFigureProps, 'fig' | 'size'>> = React.memo(
  ({ size, fig = [], className, isVisible }) => {
    const [generated, setGenerated] = React.useState<number>(0);
    const [visible, setVisible] = React.useState<boolean>(false);
    const timeout = React.useRef<NodeJS.Timeout>();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const rand = React.useMemo(() => Math.random(), [generated]);

    let figure = largeFigures[Math.floor(rand * largeFigures.length)];

    if (size === 'small') {
      figure = smallFigures[Math.floor(rand * smallFigures.length)];
    }

    if (fig.length > 0) {
      figure = fig;
    }

    const Ylength = figure.length;
    const Xlength = figure[0].length;
    const maxLength = Math.max(Xlength, Ylength);
    const animationOrder: IHash = {};
    let count = 1;

    for (let k = 0; k <= 2 * (maxLength - 1); ++k) {
      for (let y = Ylength - 1; y >= 0; --y) {
        const x = k - (Ylength - y) + 1;
        if (x >= 0 && x < Xlength && figure[y][x] === 'a') {
          animationOrder[`${y},${x}`] = count++;
        }
      }
    }

    const matrix: JSX.Element[][] = Array.from({ length: figure.length }, (_, x) =>
      Array.from({ length: figure[x].length }, (_, y) => {
        if (figure[x][y] === 'a' && visible) {
          return <AnimatedCube delay={animationOrder[`${x},${y}`]} />;
        }
        if (figure[x][y] === 'x') {
          return <Cube className={styles.cube} />;
        }

        return <div className={styles.cube} />;
      })
    );

    React.useEffect(() => {
      if (typeof isVisible === 'boolean' && isVisible && !visible) {
        timeout.current = setTimeout(() => {
          setVisible(true);
        }, 500);
      }

      return () => timeout.current && clearTimeout(timeout.current);
    }, [isVisible, visible]);

    return (
      <VisibilitySensor onChange={(v) => v && typeof isVisible === 'undefined' && setVisible(true)}>
        <div className={clsx(className, styles.table)}>
          {matrix.map((vx, xPos) => (
            <div key={`${xPos}-${JSON.stringify(animationOrder)}`} className={styles.row}>
              {vx.map((value, yPos) => (
                <React.Fragment key={`${yPos}-${JSON.stringify(animationOrder)}`}>
                  {value}
                </React.Fragment>
              ))}
            </div>
          ))}
          <div className={styles.easterEgg} onClick={() => setGenerated((i) => ++i)} />
        </div>
      </VisibilitySensor>
    );
  }
);

export default MatrixFigure;
