import React from 'react';
import { useStaticQuery, graphql, Link as GLink, GatsbyLinkProps } from 'gatsby';
import clsx from 'clsx';
import Arrow from 'components/arrow';

import * as styles from './link.module.scss';

interface LinkBasic {
  id?: string;
}
interface LinkWithSlug extends LinkBasic {
  slug: string;
  model?: {
    apiKey: string;
  };
}
interface LinkWithModel extends LinkBasic {
  slug?: string;
  model: {
    apiKey: string;
  };
}

export type LinkType = LinkWithSlug | LinkWithModel | (LinkWithSlug & LinkWithModel);

export type LinkProps = {
  className?: string;
  state?: unknown;
  activeClassName?: string;
  link?: LinkType;
  title?: string;
  arrow?: 'right' | 'left';
  arrowFill?: boolean;
  externalUrl?: string;
  onClick?: React.MouseEventHandler<HTMLElement>;
};

type GlobalSlug = {
  model: string;
  slug: string;
};

export const EmailLink: React.FC<{ email: string; title?: string; arrow?: boolean }> = ({
  email,
  title,
  arrow = false,
}) => {
  const openEmail = (address: string) => {
    if (!window) return;

    window.location.href = `mailto:${address}`;
  };

  return (
    <span className={styles.linkEmail} onClick={() => openEmail(email)}>
      {arrow && <Arrow dir={'right'} className={styles.linkEmailArrow} />}
      {title ? title.split('').reverse().join('') : email.split('').reverse().join('')}
    </span>
  );
};

const getSlug = (link: LinkType, slugs: GlobalSlug[]): string => {
  const mainSlug = slugs.find((s) => link.model && s.model === link.model.apiKey);

  if (!mainSlug && link.slug && /^\//.test(link.slug)) {
    return link.slug;
  }
  if (!mainSlug && link.slug) {
    return `/${link.slug}`;
  }

  if (!link.slug) {
    return mainSlug?.slug ?? '';
  }

  return `${mainSlug?.slug}/${link.slug}`;
};

const Link: React.FC<LinkProps> = ({
  externalUrl = '',
  activeClassName = '',
  onClick,
  state,
  ...props
}) => {
  const className = clsx(styles.link, props.arrowFill && styles.linkArrowFill, props.className);
  const { site } = useStaticQuery<GatsbyTypes.GlobalSlugsQuery>(graphql`
    query GlobalSlugs {
      site {
        siteMetadata {
          slugs {
            model
            slug
          }
        }
      }
    }
  `);

  if (!props.link) {
    switch (props.arrow) {
      case 'right':
        return (
          <div onClick={onClick} className={className}>
            {props.children}
            <Arrow className={styles.linkArrow} dir={'right'} fillStroke={props.arrowFill} />
          </div>
        );

      default:
        return (
          <div onClick={onClick} className={className}>
            {props.children}
          </div>
        );
    }
  }

  let linkProps: Omit<GatsbyLinkProps<unknown>, 'ref'>;

  if (externalUrl.length > 0) {
    switch (props.arrow) {
      case 'right':
        return (
          <a
            href={externalUrl}
            className={className}
            onClick={onClick}
            target={'_blank'}
            rel={'noreferrer'}
          >
            {props.children}
            <Arrow className={styles.linkArrow} dir={'right'} fillStroke={props.arrowFill} />
          </a>
        );

      default:
        return (
          <a
            href={externalUrl}
            className={className}
            onClick={onClick}
            target={'_blank'}
            rel={'noreferrer'}
          >
            {props.children}
          </a>
        );
    }
  } else {
    linkProps = {
      to: getSlug(props.link, site?.siteMetadata?.slugs as GlobalSlug[]),
      activeClassName,
      onClick,
      className,
      state,
    };

    switch (props.arrow) {
      case 'right':
        return (
          <GLink {...linkProps}>
            {props.children}
            <Arrow className={styles.linkArrow} dir={'right'} fillStroke={props.arrowFill} />
          </GLink>
        );

      default:
        return <GLink {...linkProps}>{props.children}</GLink>;
    }
  }
};

export default Link;

export const query = graphql`
  fragment LinkCollection on DatoCmsUnionForDatoCmsLinkLink {
    ... on DatoCmsStartPage {
      id
      model {
        apiKey
      }
    }
    ... on DatoCmsProject {
      id
      slug
      model {
        apiKey
      }
    }
    ... on DatoCmsNews {
      id
      slug
      model {
        apiKey
      }
    }
    ... on DatoCmsPage {
      id
      slug
      model {
        apiKey
      }
    }
    ... on DatoCmsContactPage {
      id
      model {
        apiKey
      }
    }
    ... on DatoCmsNewsArchive {
      id
      model {
        apiKey
      }
    }
    ... on DatoCmsCategory {
      id
      slug
      model {
        apiKey
      }
    }
    ... on DatoCmsProjectArchive {
      id
      model {
        apiKey
      }
    }
    ... on DatoCmsProjectCategory {
      id
      slug
      model {
        apiKey
      }
    }
  }

  fragment Link on DatoCmsLink {
    id
    externalUrl
    text
    link {
      ...LinkCollection
    }
  }
`;
